<template>

  <div v-if="$isMobile()" class="container_about">
    <div class="pa-1">
      <div class="d-flex pa-2 mb-3 align-center">
        <blue-circle class="mr-3" />
        <h3 class="color_default">Notifikasi </h3>
      </div>

      <v-overlay
        :absolute="true"
        color="white"
        opacity="1"
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="#00b4cc"
        ></v-progress-circular>
        <b class="color_default">Loading...</b>
      </v-overlay>

      <v-card
        class="rounded-max custom_card pa-5"
        v-if="!loading && data.length>=1"
      >
        <v-card
        style="background-color: #EFF2F8;"
          class="rounded-lg pa-3 mb-3"
          v-for="(item, idx) in data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)"
          :key="idx"
          flat
          color="#FBFBFB"
        >
          <v-row v-if="item.tipeNotif==='notif'">
            <v-col :cols="role==='Administrator' ? '3':'6'">
              <div class="d-flex align-center h_percent">
                <v-icon
                  color="#00b4cc"
                  class="mr-2"
                  v-if="!item.read_at"
                >mdi-alert-decagram</v-icon>
                <p class="ma-0 pa-0 small_txt">
                  {{item.data.title}}
                </p>
              </div>
            </v-col>
            <v-col
              cols="3"
              v-if="role==='Administrator'"
            >
              <div class="d-flex align-center h_percent">
                <p
                  class="ma-0 pa-0 small_txt"
                  style="color: grey;"
                >
                  {{item.data.message}}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex align-center h_percent">
                <p class="ma-0 pa-0 small_txt color_txt">
                  {{ $date(item.data.created_at).format("D MMMM YYYY") }}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="text-capitalize"
                small
                dark
                depressed
                color="#00b4cc"
                rounded
                @click="toRedirect(item)"
                v-if="
                  item.data.data && item.data.data.notif_type === 'new-user' && item.related_model.isActive === 'NonActive' ||
                  item.data.data && item.data.data.notif_type === 'confirm-form' && item.related_model.isActive !== 2"
              >
                {{
                  item.data.data.notif_type === 'new-user' ? 'Aktivasi' : 'Konfirmasi'
                }}
              </v-btn>
              <p
                class="color_default"
                v-if="item.data.data && item.data.data.notif_type === 'confirm-form' && item.related_model.isActive === 2"
              >Telah dikonfirmas</p>
            </v-col>
          </v-row>

          <v-row class="d-flex flex-column" v-else>
            <v-col >
              <div class="d-flex align-center h_percent">
                <!-- <v-icon color="#00b4cc" class="mr-2" v-if="!item.read_at">mdi-alert-decagram</v-icon> -->
                <p class="ma-0 pa-0 small_txt">
                  {{item.title}}
                </p>
              </div>
            </v-col>

            <v-col >
              <div class="d-flex align-center h_percent">
                <p class="ma-0 pa-0 small_txt color_txt">
                  {{ item.period }}
                </p>
              </div>
            </v-col>

            <v-col class="d-flex justify-center">
              <v-btn
                class="text-capitalize"
                small
                dark
                depressed
                color="#00b4cc"
                rounded
                @click="toRedirect(item)"
              >
                Isi Formulir
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
        <div class="d-flex justify-center pa-3">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              :color="currentPage <= 1 ? '#999999' : '#00b4cc'"
              dark
              class="pa-2 custom_shadow"
              @click="currentPage <= 1 ? currentPage : currentPage--"
            ><v-icon small>mdi-arrow-left</v-icon></v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">{{currentPage}} of {{Math.ceil(data.length/perPage)}}</p>
            </div>
            <v-btn
              small
              min-width="10px"
              :color="currentPage >= Math.ceil(data.length/perPage) ? '#999999' : '#00b4cc'"
              dark
              class="pa-2 custom_shadow"
              @click="currentPage >= Math.ceil(data.length/perPage) ? currentPage : currentPage++"
            ><v-icon small>mdi-arrow-right</v-icon></v-btn>
          </div>
        </div>
      </v-card>

      <v-card
        class="rounded-max custom_card pa-5"
        v-if="!loading && data.length<=0"
      >
        <div class="d-flex justify-center mt-1">
          <b>Tidak Ada Notifikasi</b>
        </div>
      </v-card>
    </div>
  </div>

  <div v-else class="container_about">
    <div class="pa-5">
      <div class="d-flex pa-2 mb-3 align-center">
        <blue-circle class="mr-3" />
        <h3 class="color_default">Notifikasi</h3>
      </div>
      <v-overlay
        :absolute="true"
        color="white"
        opacity="1"
        :value="loading"
      >
        <v-progress-circular
          indeterminate
          class="mr-2"
          color="#00b4cc"
        ></v-progress-circular>
        <b class="color_default">Loading...</b>
      </v-overlay>
      <v-card
        class="rounded-max custom_card pa-5"
        v-if="!loading && data.length>=1"
      >
        <v-card
          class="rounded-lg pa-3 mb-3"
          v-for="(item, idx) in data.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)"
          :key="idx"
          flat
          color="#FBFBFB"
        >
          <v-row v-if="item.tipeNotif==='notif'">
            <v-col :cols="role==='Administrator' ? '3':'6'">
              <div class="d-flex align-center h_percent">
                <v-icon
                  color="#00b4cc"
                  class="mr-2"
                  v-if="!item.read_at"
                >mdi-alert-decagram</v-icon>
                <p class="ma-0 pa-0 small_txt">
                  {{item.data.title}}
                </p>
              </div>
            </v-col>
            <v-col
              cols="3"
              v-if="role==='Administrator'"
            >
              <div class="d-flex align-center h_percent">
                <p
                  class="ma-0 pa-0 small_txt"
                  style="color: grey;"
                >
                  {{item.data.message}}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex align-center h_percent">
                <p class="ma-0 pa-0 small_txt color_txt">
                  {{ $date(item.data.created_at).format("D MMMM YYYY") }}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="text-capitalize"
                small
                dark
                depressed
                color="#00b4cc"
                rounded
                @click="toRedirect(item)"
                v-if="
                  item.data.data && item.data.data.notif_type === 'new-user' && item.related_model.isActive === 'NonActive' ||
                  item.data.data && item.data.data.notif_type === 'confirm-form' && item.related_model.isActive !== 2"
              >
                {{
                  item.data.data.notif_type === 'new-user' ? 'Aktivasi' : 'Konfirmasi'
                }}
              </v-btn>
              <p
                class="color_default"
                v-if="item.data.data && item.data.data.notif_type === 'confirm-form' && item.related_model.isActive === 2"
              >Telah dikonfirmas</p>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="6">
              <div class="d-flex align-center h_percent">
                <!-- <v-icon color="#00b4cc" class="mr-2" v-if="!item.read_at">mdi-alert-decagram</v-icon> -->
                <p class="ma-0 pa-0 small_txt">
                  {{item.title}}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex align-center h_percent">
                <p class="ma-0 pa-0 small_txt color_txt">
                  {{ item.period }}
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <v-btn
                class="text-capitalize"
                small
                dark
                depressed
                color="#00b4cc"
                rounded
                @click="toRedirect(item)"
              >
                Isi Formulir
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <div class="d-flex justify-center pa-3">
          <div class="d-flex align-center pa-2 round_paginate">
            <v-btn
              small
              min-width="10px"
              :color="currentPage <= 1 ? '#999999' : '#00b4cc'"
              dark
              class="pa-2 custom_shadow"
              @click="currentPage <= 1 ? currentPage : currentPage--"
            ><v-icon small>mdi-arrow-left</v-icon></v-btn>
            <div class="mx-3">
              <p class="ma-0 color_default">{{currentPage}} of {{Math.ceil(data.length/perPage)}}</p>
            </div>
            <v-btn
              small
              min-width="10px"
              :color="currentPage >= Math.ceil(data.length/perPage) ? '#999999' : '#00b4cc'"
              dark
              class="pa-2 custom_shadow"
              @click="currentPage >= Math.ceil(data.length/perPage) ? currentPage : currentPage++"
            ><v-icon small>mdi-arrow-right</v-icon></v-btn>
          </div>
        </div>
      </v-card>
      <v-card
        class="rounded-max custom_card pa-5"
        v-if="!loading && data.length<=0"
      >
        <div class="d-flex justify-center mt-1">
          <b>Tidak Ada Notifikasi</b>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BlueCircle from "../../components/General/BlueCircle";
import { FORM_FILL_STATUS } from "../../constants/constant";

export default {
  components: {
    BlueCircle,
  },
  name: "listNotif",
  mounted() {
    this.getData();
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      data: [],
      loading: false,
      perPage: 5,
      currentPage: 1,
      loading: false,
    };
  },
  methods: {
    getData() {
      const date_now = this.$date().format("YYYY-MM-DD");
      this.loading = true;
      let body = {
        tipeNotif: "all",
      };
      this.data = [];
      this.$store.dispatch("notifikasi/getNotifikasi", body).then((data) => {
        data.notifications.forEach((element) => {
          if (element.related_model) {
            element.tipeNotif = "notif";
            this.data.push(element);
          }
        });
        data.form_fillings.forEach((element) => {
          if (
            element.fill_status === FORM_FILL_STATUS.Unfilled ||
            element.fill_status === FORM_FILL_STATUS.NeedRevision || 
            element.fill_status === FORM_FILL_STATUS.Unsend
          ) {
            element.redirect_url = "/formulir";
            let prefix_title = "Pengisian";
            if (element.fill_status === FORM_FILL_STATUS.NeedRevision) {
              prefix_title = "Perbaikan";
            }

            if (element.type === "kesling") {
              element.redirect_url = `/formulir/kesehatan-lingkungan/redaksi/${date_now}`;
              element.title = `${prefix_title} Formulir Limbah Kesehatan Lingkungan`;
            } else if (element.type === "kesling-key") {
              element.redirect_url = `/formulir/pertanyaan-kunci/redaksi/${date_now}`;
              element.title = `${prefix_title} Formulir Limbah Kesehatan Lingkungan ( Pertanyaan Kunci )`;
            } else if (element.type === "covid") {
              element.redirect_url = `/formulir/covid19/redaksi/${date_now}`;
              element.title = `${prefix_title} Formulir Limbah Covid-19`;
            } else if (element.type === "fasyankes") {
              element.redirect_url = `/formulir/limbah-fasyankes/redaksi/${date_now}`;
              element.title = `${prefix_title} Formulir Limbah Fasyankes`;
            } else {
              element.title = `${prefix_title} Formulir Limbah Sanitarian Kit`;
            }
            this.data.push(element);
          }
        });
        this.loading = false;
      });
    },
    toRedirect(item) {
      if (item.tipeNotif === "notif") {
        let body = {
          notificationId: item.id,
        };
        this.$store.dispatch("notifikasi/updateToRead", body).then((data) => {
          this.$router.push({ path: item.data.redirect_url });
        });
      } else {
        this.$router.push({ path: item.redirect_url });
      }
    },
  },
};
</script>

<style scoped>
.container_about {
  padding: 20px;
}
.round_paginate {
  border: 1px solid rgba(215, 215, 215, 1);
  border-radius: 10px;
  width: max-content;
}
</style>